<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <div class="col-12">
            <div class="card">
                <Toast />
                <Panel header="Administrar Usuarios">
                     <BasicFormToolbar @new="openNew" @refresh="refresh" :actions="['new', 'refresh']" />
                <BasicDatatable :rowedit="true" :rowaction="true" :rowdelete="true" :headers="headers" :rows="entities" @edited="edit" @deleted="confirmDelete" />
                <Dialog ref="dialog" v-model:visible="newDialog" :style="{ width: '600px' }" :header="!editMode ? 'Nuevo Usuario' : 'Editar Usuario'" :modal="true" class="p-fluid">
                    <TabView ref="tab">
                        <TabPanel header="General">
                            <div class="p-fluid formgrid grid">
                                <FormInputText wrapperClass="field col-12" label="Username" v-model="entity.username" :valid="validate.validations.username" />
                                <FormInputText v-if="entity.id == null" wrapperClass="field col-12" label="Password" v-model="entity.password" :valid="validate.validations.password" type="password" />
                                <FormInputText wrapperClass="field col-12" label="Nombre" v-model="entity.name" :valid="validate.validations.name" />
                                <FormInputText wrapperClass="field col-12" label="Apellido" v-model="entity.lastname" :valid="validate.validations.lastname" />
                                <FormInputText wrapperClass="field col-12" label="E-mail" v-model="entity.email" :valid="validate.validations.email" />
                                <FormDropdown wrapperClass="field col-6" label="Perfil" v-model="entity.id_erp_profile" :valid="validate.validations.id_erp_profile" :options="profiles" optionLabel="name" optionValue="id" />
                                <FormDropdown wrapperClass="field col-3" label="Activo" v-model="entity.is_active" :options="[
                                            { id: true, name: 'SI' },
                                            { id: false, name: 'NO' },
                                        ]" optionLabel="name" optionValue="id" />
                                <FormDropdown wrapperClass="field col-3" label="API" v-model="entity.is_api_user" :options="[
                                    { id: true, name: 'SI' },
                                    { id: false, name: 'NO' },
                                ]" optionLabel="name" optionValue="id" />
                            </div>
                        </TabPanel>
                        <TabPanel v-if="entity.id" header="Registro">
                            <FormDropdown wrapperClass="field col-12" label="Usuario registrado" :disabled="true" v-model="entity.is_registered" :options="[
                                    { id: true, name: 'SI' },
                                    { id: false, name: 'NO' },
                                ]" optionLabel="name" optionValue="id" />
                            <Divider />
                            <div class="col-12">
                                <Button @click="sendConfirm" label="Reenviar correo de registro" icon="pi pi-send" :disabled="entity.is_registered" />
                            </div>
                        </TabPanel>
                        <TabPanel header="Accesos">
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12">
                                    <BasicDatatable :showVisibleColumns="false" v-model="entity.accesses" @selected-change="getAcceses" :headers="branchHeader" :rows="branches" selectionModeColumn="multiple" />
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
                        <Button label="Guardar" icon="pi pi-check" class="p-button-text" :loading="loading" @click="save" />
                    </template>
                </Dialog>
                <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteEntity" /> 
                </Panel>               
            </div>
        </div>
    </div>
</template>

<script>
import { ERPUser } from '../../models/seguridad/ERPUser';
import { FilterMatchMode } from 'primevue/api';
import { HeaderGrid, Rule, validate, fillObject, Toast, ErrorToast } from '../../utilities/General';
import Loader from '../../components/general/Loader.vue';
import FormInputText from '../../components/general/FormInputText.vue';
import FormDropdown from '../../components/general/FormDropdown.vue';
import ConfirmDeleteDialog from '../../components/general/DeleteDialog.vue';
import BasicFormToolbar from '../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../components/general/BasicDatatable.vue';
import Session from '../../mixins/sessionMixin';
import { ERPProfile } from '../../models/seguridad/ERPProfile';
import { Branch } from '../../models/general/Branch';
export default {
    mixins: [Session],
    data() {
        return {
            entity: null,
            editMode: false,
            entities: [],
            uploadURL: null,
            newDialog: false,
            deleteDialog: false,
            profiles: [],
            filters: {},
            validate: {
                valid: false,
                validations: {
                    username: null,
                    name: null,
                    lastname: null,
                    email: null,
                    id_erp_profile: null,
                    password: null,
                },
            },
            branches: [],
            //* Headers donde pondremos el nombre y el valor del datatable
            headers: [new HeaderGrid('Username', 'username'), new HeaderGrid('Nombre', 'name'), new HeaderGrid('Apellido', 'lastname'), new HeaderGrid('Correo', 'email'), new HeaderGrid('Perfil', 'erp_profile')],
            branchHeader: [new HeaderGrid('Empresa', 'company_name'), new HeaderGrid('Sucursal', 'name')],
            loading: false,
        };
    },
    computed: {
        rules() {
            if (this.entity.id) {
                return [new Rule({ name: 'username' }), new Rule({ name: 'name' }), new Rule({ name: 'lastname' }), new Rule({ name: 'email' }), new Rule({ name: 'id_erp_profile' })];
            }else {
                return [new Rule({ name: 'username' }), new Rule({ name: 'name' }), new Rule({ name: 'lastname' }), new Rule({ name: 'email' }), new Rule({ name: 'id_erp_profile' }), new Rule({ name: 'password' })];
            }
        }
    },
    components: { Loader,ConfirmDeleteDialog, FormDropdown, BasicDatatable, BasicFormToolbar, FormInputText },
    methods: {
        getAcceses(accesses) {
            console.log(accesses);
            this.entity.accesses = accesses;
        },
        openNew() {
            this.entity = new ERPUser(this.session);
            this.newDialog = true;
            this.editMode = false;
        },
        hideDialog() {
            this.newDialog = false;
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                this.entity.accesses.forEach((element) => {
                    element.id_branch = element.id;
                    delete element.id;
                });
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.editMode) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });

                    //* Modificamos el listado pah
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    //* Agregamos mas datos
                    entity.erp_profile = this.profiles.find((x) => x.id == entity.id_erp_profile).name;
                    entity.password = '';
                    this.entities[index] = entity;
                } else {
                    //* Creamos uno nuevo
                    let entity = await this.entity.save();
                    //* Agregamos un dato extra
                    entity.erp_profile = this.profiles.find((x) => x.id == entity.id_erp_profile).name;
                    entity.password = '';
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion guardada con exito',
                        })
                    );
                }
                this.entity = new ERPUser(this.session);
                this.newDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            //* Cambiar el menu
            let data = [];
            entity.accesses.forEach((x) => {
                //! CAMBIAR
                let br = this.branches.find((z) => z.id == x.id_branch);
                if (!data) br = this.branches.find((z) => z.id_branch == x.id_branch);
                let branch = {
                    ...br,
                };
                data.push(branch);
            });
            this.entity = fillObject(this.entity, entity);
            this.entity.accesses = data;
            this.newDialog = true;
            this.editMode = true;
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },
        async sendConfirm() {
            try {
                this.loading = true;
                await this.entity.sendConfirm(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Usuario',
                    detail: 'Correo enviado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new ERPUser(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        uploadError() {
            this.$toast.add(new ErrorToast('Error al intentar importar archivo'));
        },
        async refresh() {
            this.loading = true;
            try {
                this.entities = await this.entity.data();
                this.profiles = await new ERPProfile().all();
                this.branches = await new Branch().data();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
    created() {
        console.log(this.session);
        this.entity = new ERPUser(this.session);
        this.initFilters();
    },
    async mounted() {
        await this.refresh();
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
